import React from 'react';
import './Hero.scss';
import BTN from '../BTN';
import { FaRegAddressBook } from 'react-icons/fa';

export default () => {
  return (
    <section id="hero" className="hero">
      <div className="hero__container">
        <div className="hero__card">
          <div className="hero__aside">
          </div>
          <div className="hero__header">
            <h1 className="hero__title">Teron Bullock</h1>
            <p className="hero__subtitle">Web Developer</p>
          </div>
        </div>
        <div className="hero__footer">
          <BTN 
            class="btn--hero btn--action btn--shadow"
            href="/contact/teron_bullock.vcf"
            label="ADD TO CONTACTS"
            icon={<FaRegAddressBook className="btn__icon"/>}
          />
        </div>
      </div>
    </section>
  );
};
