import React from 'react';
import './ExitBTN.scss';
import { Link } from 'react-router-dom';

export default props => {
  return (
    <React.Fragment>
      <Link className="exit-btn" to={props.href}>
        {props.label}
      </Link>
    </React.Fragment>
  );
};
