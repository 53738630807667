import React from 'react';
import './RouteLink.scss';
import Icon from '../Icons/';
import { Link } from 'react-router-dom';

export default (props) => {
  return (
    <div className="contact">
      <Link className="contact__link" to={props.link}>
        <Icon 
          type={props.iconType}
        />
        <p className="contact__label" >{props.label}</p>
      </Link>
    </div>
  );
};
