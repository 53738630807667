import React from 'react';
import './Share.scss';
import ExitBTN from '../../components/ExitBTN';

export default props => {
  return (
    <section className="share">
      <div className="share__header">
        <ExitBTN href="/" label="X" />
      </div>
      <div className="share__content">
        <h1 className="share__title">Teron Bullock</h1>
        <div className="share__aside">
          <img className="share__img" src="/img/qrCode.png" alt="qr code" />
          <p className="share__copy">
            To share card scan this code with your camera.
          </p>
        </div>
      </div>
    </section>
  );
};
