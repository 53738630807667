import React from 'react';
import './BTN.scss';

export default props => {
  return (
    <React.Fragment>
      <a className={`btn ${props.class}`} href={props.href}>
        {props.icon}
        {props.label}
      </a>
    </React.Fragment>
  );
};
