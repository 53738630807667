import React from 'react';
import './App.scss';
import Home from './routers/Home';
import Share from './routers/Share';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

export default () => {
  return (
    <Router>
      <div className="app">
        <Route exact path="/" component={Home} />
        <Route path="/share" component={Share} />
      </div>
    </Router>
  );
};
