import React from 'react';
import './Icons.scss';

export default (props) => {
  return (
    <div className="icon">
      <span className="icon__type">
        {props.type}
      </span>
    </div>
  );
};
