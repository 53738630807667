import React from 'react';
import './Home.scss';

import Header from '../../components/Header';
import Hero from '../../components/Hero/';
import Contact from '../../components/Contact';
import RouteLink from '../../components/RouteLink';
import { FiPhone, FiMonitor, FiInstagram } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { FiTwitter } from 'react-icons/fi';
import { ImQrcode } from 'react-icons/im';


export default props => {
  return (
    <React.Fragment>
       <Header />
      <div className="app__container">
        <Hero />
        <section className="">
          <Contact
            link="tel:+18455336183"
            iconType={<FiPhone className="icon__type" />}
            label="845 533-6183"
          />
          <Contact
            link="mailto:teron.bullock@gmail.com"
            iconType={<HiOutlineMail className="icon__type" />}
            label="teron.bullock@gmail.com"
          />
          <Contact
            link="http://teronbullock.com/"
            iconType={<FiMonitor className="icon__type" />}
            label="teronbullock.com"
          />
          <Contact
            link="https://twitter.com/teronbullock"
            iconType={<FiTwitter className="icon__type" />}
            label="@Teronbullock"
          />
          <Contact
            link="https://www.instagram.com/teron.codes/"
            iconType={<FiInstagram className="icon__type" />}
            label="teron.codes"
          />
          <RouteLink
            link="/share"
            iconType={<ImQrcode className="icon__type" />}
            label="Contact QR Code"
          />
        </section>
      </div>
    </React.Fragment>
  );
};
