import React from 'react';
import './Header.scss';

export default () => {
  return (
    <div id="header" className="header">
      <div className="header__container">
        <h2 className="header__title">
          <a className="header__title-link" href="http://teronbullock.com/">
            BUSINESS CARD
          </a>
        </h2>
      </div>
    </div>
  );
};
