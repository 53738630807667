import React from 'react';
import './Contact.scss';
import Icon from '../Icons/';


export default (props) => {
  return (
    <div className="contact">
      <a className="contact__link" href={props.link}>
        <Icon 
          type={props.iconType}
        />
        <p className="contact__label" >{props.label}</p>
      </a>
    </div>
  );
};
